<template>
    <v-card flat>
      <v-card-text>
        <v-text-field
            v-model="opcaoEditada.nomeOpcao"
            :rules="[campoObrigatorioRule]"
            dense
            label="Nome"
            name="opcaoNome"
            outlined
            validate-on-blur
        />
        <v-text-field
            v-model="opcaoEditada.turno"
            :rules="[campoObrigatorioRule]"
            dense
            label="Turno"
            name="opcaoTurno"
            outlined
            validate-on-blur
        />
        <v-text-field
            v-model="opcaoEditada.polo"
            :rules="[campoObrigatorioRule]"
            dense
            label="Polo"
            name="opcaoPolo"
            outlined
            validate-on-blur
        />
        <v-text-field
            v-model="opcaoEditada.formacao"
            :rules="[campoObrigatorioRule]"
            dense
            label="Formação"
            name="opcaoFormacao"
            outlined
            validate-on-blur
        />
        <v-text-field
            v-model="opcaoEditada.codigo"
            :rules="[campoObrigatorioRule]"
            dense
            label="Código"
            name="opcaoCodigo"
            outlined
            validate-on-blur
        />
        <v-text-field
            v-model="opcaoEditada.codigoInterno"
            dense
            label="Código SIGA"
            name="opcaoCodigoSIGA"
            outlined
            validate-on-blur
        />
        <v-select
            v-model="opcaoEditada.tipoAlocacao"
            :items="tiposAlocacao"
            :rules="[campoObrigatorioRule]"
            dense
            item-text="descricao"
            label="Tipo alocação"
            outlined
            return-object
            validate-on-blur
        />
        <v-select
            v-if="processoSelecionado.tipoProcesso.possuiGrupoOpcao"
            v-model="opcaoEditada.grupo"
            :items="grupos"
            :rules="[campoObrigatorioRule]"
            dense
            :item-text="
                          (item) => item.nome + ' - ' + item.descricao
                        "
            label="Grupo"
            outlined
            return-object
            validate-on-blur
        />
        <v-text-field
            v-model="opcaoEditada.numeroVagas"
            dense
            label="Número de vagas"
            :rules="[campoObrigatorioRule]"
            v-mask="'####'"
            maxlength="4"
            name="opcaoNumeroVagas"
            outlined
        />
        <v-text-field
            v-model="opcaoEditada.quantidadeQuestoes"
            dense
            label="Quantidade de questões"
            :rules="[campoObrigatorioRule]"
            v-mask="'###'"
            maxlength="3"
            name="opcaoQuantidadeQuestoes"
            outlined
        />
        <v-text-field
            v-model="opcaoEditada.quantidadeAvaliadores"
            :rules="[campoObrigatorioRule]"
            v-mask="'##'"
            maxlength="2"
            dense
            label="Quantidade de avaliadores"
            name="opcaoQuantidadeAvaliadores"
            outlined
        />
        <v-textarea
            rows="2"
            v-model="opcaoEditada.observacao"
            dense
            label="Observação"
            name="opcaoObservacao"
            outlined
        />
        <v-text-field
            v-model="opcaoEditada.opcaoPesoNotaEnem.pesoLinguagens"
            v-mask="'##'"
            maxlength="2"
            dense
            label="Peso Linguagens"
            name="opcaoPesoLinguagens"
            outlined
        />
        <v-text-field
            v-model="
                          opcaoEditada.opcaoPesoNotaEnem.pesoCienciasHumanas
                        "
            v-mask="'##'"
            maxlength="2"
            dense
            label="Peso Ciências Humanas"
            name="opcaoPesoCienciasHumanas"
            outlined
        />
        <v-text-field
            v-model="
                          opcaoEditada.opcaoPesoNotaEnem.pesoCienciasNaturais
                        "
            v-mask="'##'"
            maxlength="2"
            dense
            label="Peso Ciências Naturais"
            name="opcaoPesoCienciasNaturais"
            outlined
        />
        <v-text-field
            v-model="opcaoEditada.opcaoPesoNotaEnem.pesoMatematica"
            v-mask="'##'"
            maxlength="2"
            dense
            label="Peso Matemática"
            name="opcaoPesoMatematica"
            outlined
        />
        <v-text-field
            v-model="opcaoEditada.opcaoPesoNotaEnem.pesoRedacao"
            v-mask="'##'"
            maxlength="2"
            dense
            label="Peso Redação"
            name="opcaoPesoRedacao"
            outlined
        />

      </v-card-text>
    </v-card>
</template>

<script>

import rules from "@/commons/rules";
import tipoAlocacaoService from "@/services/tipoAlocacao.service";
import {mapState} from "vuex";

export default {
  name: "OpcaoForm",
  props: {
    value: {type: Object, required: true},
    grupos: {type: Array, required: true}
  },

  computed: {
    ...mapState("processoSelecionado", ["processoSelecionado"])
  },

  data(){
    return {
      opcaoEditada: { opcaoPesoNotaEnem: {} },
      tiposAlocacao: []
    }
  },

  methods: {
    ...rules
  },

  async created(){
    await tipoAlocacaoService
        .recuperarTiposAlocacao()
        .then((response) => {
          this.tiposAlocacao = response.data;
        })
        .catch(() => {});

    this.opcaoEditada = this.$props.value;
  }
}
</script>

<style scoped>

</style>