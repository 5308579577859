import Vue from "vue";

export default {
  recuperarOpcaoPorOid(opcaoOid) {
    return Vue.axios.get("opcao/" + opcaoOid);
  },

  recuperarOpcoesConsulta(processoOid) {
    return Vue.axios.get("opcao/filtro/consulta", {
      params: { processoOid }
    });
  },

  recuperarOpcoesPorProcesso(processoOid) {
    return Vue.axios.get("opcao/processo/" + processoOid );
  },

  recuperarOpcoesImportacaoPorProcesso(processoOid) {
    return Vue.axios.get("opcao/import/processo/" + processoOid );
  },

  recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
    return Vue.axios.post("opcao/filtro", {
      params: {
        processoOid: paramsFiltro.processoOid
      },
      pagina,
      numElementosPagina,
      ordenacao
    });
  },

  recuperarOpcoesDisponiveisIVTHE(processoOid) {
    return Vue.axios.get("opcao/disponiveis/IVTHE/" + processoOid);
  },

  recuperarOpcoesDisponiveisIVLIB(processoOid) {
    return Vue.axios.get("opcao/disponiveis/IVLIB/" + processoOid);
  },

  recuperarOpcoesConsultaPorNomeOpcaoOuNomeProcesso(paramsFiltro){
    return Vue.axios.get("opcao/filtro/consulta/nome", {
      params: {
            nomeOpcao: paramsFiltro.nomeOpcao,
            nomeProcesso: paramsFiltro.nomeProcesso
        }
    });
  },

  recuperarOpcoesPorEscopoUsuario(usuarioOid){
    return Vue.axios.get("opcao/avaliador/" + usuarioOid );
  },

  criarOpcao(dados, processoOid) {
    return Vue.axios.post("opcao", {
      oid: dados.oid,
      codigo: dados.codigo,
      codigoInterno: dados.codigoInterno,
      nomeOpcao: dados.nomeOpcao,
      turno: dados.turno,
      polo: dados.polo,
      formacao: dados.formacao,
      numeroVagas: dados.numeroVagas,
      quantidadeQuestoes: dados.quantidadeQuestoes,
      quantidadeAvaliadores: dados.quantidadeAvaliadores,
      observacao: dados.observacao,
      processo: {
        oid: processoOid
      },
      tipoAlocacao: dados.tipoAlocacao,
      grupo: dados.grupo,
      opcaoPesoNotaEnem: dados.opcaoPesoNotaEnem
    });
  },

  atualizarOpcao(dados, processoOid) {
    console.log(dados);
    return Vue.axios.put("opcao", {
      oid: dados.oid,
      codigo: dados.codigo,
      codigoInterno: dados.codigoInterno,
      nomeOpcao: dados.nomeOpcao,
      turno: dados.turno,
      polo: dados.polo,
      formacao: dados.formacao,
      numeroVagas: dados.numeroVagas,
      quantidadeQuestoes: dados.quantidadeQuestoes,
      quantidadeAvaliadores: dados.quantidadeAvaliadores,
      observacao: dados.observacao,
      tipoAlocacao: dados.tipoAlocacao,
      grupo: dados.grupo,
      processo: {
        oid: processoOid
      },
      opcaoPesoNotaEnem: dados.opcaoPesoNotaEnem
    });
  },

  vincularSalasOpcao(salasSelecionadas, opcaoOid){
    return Vue.axios.put("opcao/alocarSalas/" + opcaoOid, salasSelecionadas);
  },

  importarOpcoes(opcoesSelecionadas, processoOid) {
    return Vue.axios.post("opcao/importar/" + processoOid, opcoesSelecionadas);
  },

  salvarOpcoesPorEscopoUsuario(usuarioOid, opcoesEscolhidas){
    return Vue.axios.post("opcao/avaliador/" + usuarioOid, opcoesEscolhidas);
  },

  deletarOpcao(opcaoOid){
    return Vue.axios.delete("opcao/" + opcaoOid);
  },

  deletarOpcaoEscopoUsuario(usuarioOid, opcaoOid){
    return Vue.axios.delete("opcao/avaliador/" + usuarioOid + "/" + opcaoOid);
  },

  deletarSalaOpcao(opcaoOid, salaOid){
    return Vue.axios.delete("opcao/salaOpcao/" + opcaoOid + "/" + salaOid);
  },

  recuperarOpcoesRelatorioPorProcesso(processoOid){
    return Vue.axios.get("opcao/relatorio/processo/" + processoOid);
  }

};
