<template>
  <v-container fluid>
    <div class="mb-5 d-flex justify-end">
      <v-btn
          small
          color="primary"
          class="mr-2"
          @click="exportarTabela"
      >
        <v-icon left>mdi-microsoft-excel</v-icon>
        Exportar Planilha
      </v-btn>
      <v-dialog width="900" scrollable persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              small
              :disabled="loading"
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="recuperarProcessosAnteriores"
          >
            <v-icon left>mdi-import</v-icon>
            Importar Opções
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-card-title class="primary justify-start white--text">
              Importar Opções de Processo Anterior
              <v-spacer/>
              <v-btn icon color="white" @click="dialog.value = false">
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <EscolhaProcessoAutocomplete
                    v-if="dialog.value"
                    v-model="processoSelecionadoImportacao"
                    :processos="processosAnteriores"
                />
              </v-container>
              <div class="d-flex justify-center">
                <SideBySideMultiSelect
                    v-if="dialog.value"
                    v-model="opcoesEscolhidasParaImportar"
                    :items="opcoesProcessoAnterior"
                />
              </div>
              <v-card-actions class="justify-center">
                <v-btn color="error" @click="dialog.value = false"
                >Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="
                    importarOpcoes();
                    dialog.value = false;
                  "
                >Confirmar
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <v-btn
          class="ml-2"
          small
          :disabled="loading"
          color="primary"
          @click="criarOpcao"
      >
        <v-icon left>mdi-plus-thick</v-icon>
        Adicionar
      </v-btn>
    </div>
    <v-data-table
        :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [25, 50, 100, 200, 500],
      }"
        :headers="headers"
        :items="elementos"
        :loading="loading"
        :options.sync="options"
        :server-items-length="numTotalElementos"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1"
        multi-sort
        calculate-widths
        show-expand
        dense
        :expanded.sync="expanded"
        item-key="oid"
    >
      <template v-slot:item.actions="{ item }">
        <v-row justify="center" align="center">
          <v-col :cols="processoSelecionado.possuiAlocacao ? '4' : '6'">
            <v-dialog width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    :disabled="loading"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    color="error"
                    icon
                    x-small
                >
                  <v-icon> mdi-delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title class="primary justify-start white--text"
                  >Confirmar Exclusão
                  </v-card-title>
                  <v-card-text
                      style="font-size: 15pt; text-align: center"
                      class="mt-5 font-weight-bold"
                  >
                    Deseja Realmente Excluir esta Opção? Esta ação não poderá
                    ser desfeita!
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                        color="primary"
                        @click="
                        deletarOpcao(item);
                        dialog.value = false;
                      "
                    >Confirmar
                    </v-btn>
                    <v-btn color="error" @click="dialog.value = false"
                    >Cancelar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <v-col :cols="processoSelecionado.possuiAlocacao ? '4' : '6'">
            <v-btn
                color="accent"
                class="mr-3"
                icon
                x-small
                @click="editarOpcao(item)"
            >
              <v-icon> mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="processoSelecionado.possuiAlocacao"
                 cols="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="info"
                    class="mr-3"
                    icon
                    x-small
                    @click="carregarSalasOpcao(item)"
                >
                  <v-icon> mdi-chair-school</v-icon>
                </v-btn>
              </template>
              <span>Salas</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="my-1" justify="space-around">
            <v-col cols="3">
              <v-card outlined>
                <v-card-title class="subtitle-2"> Pesos</v-card-title>
                <v-card-text class="mt-n4">
                  <tr>
                    <td>
                      <span class="font-weight-bold">Linguagens: </span>
                      {{ item.pesoLinguagens || "---" }}
                    </td>
                  </tr>
                  <tr>
                    <span class="font-weight-bold"> Ciências Humanas: </span>
                    {{
                      item.pesoCienciasHumanas || "---"
                    }}
                  </tr>
                  <tr>
                    <span class="font-weight-bold"> Ciências Naturais: </span>
                    {{
                      item.pesoCienciasNaturais || "---"
                    }}
                  </tr>
                  <tr>
                    <span class="font-weight-bold"> Matemática: </span>
                    {{
                      item.pesoMatematica || "---"
                    }}
                  </tr>
                  <tr>
                    <span class="font-weight-bold"> Redação: </span>
                    {{
                      item.pesoRedacao || "---"
                    }}
                  </tr>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card outlined>
                <v-card-text>
                  <tr>
                    <td>
                      <span class="font-weight-bold">
                        Quantidade de Questões:
                      </span>
                      {{ item.quantidadeQuestoes || "---" }}
                    </td>
                  </tr>
                  <tr>
                    <span class="font-weight-bold">
                      Quantidade de Avaliadores:
                    </span>
                    {{
                      item.quantidadeAvaliadores || "---"
                    }}
                  </tr>
                </v-card-text>
              </v-card>
              <v-card outlined class="mt-3">
                <v-card-text>
                  <tr>
                    <span class="font-weight-bold"> Código Interno: </span>
                    {{
                      item.codigoInterno || "---"
                    }}
                  </tr>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card outlined>
                <v-card-text>
                  <tr>
                    <td>
                      <span class="font-weight-bold">Observação:</span>
                      {{ item.observacao || "---" }}
                    </td>
                  </tr>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-if="dialog" v-model="dialog" width="800" persistent scrollable>
      <v-card>
        <v-card-title class="primary justify-start white--text">
          {{ isCriandoOpcao ? "Criar Opção" : "Editar Opção" }}
          <v-spacer/>
          <v-btn icon color="white" @click="cancelarEdicaoOpcao">
            <v-icon> mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card-text>
            <v-form
                ref="edicaoOpcaoForm"
                @submit.prevent="salvarOpcaoEditada"
            >
              <OpcaoForm
                  v-model="this.opcaoEditada"
                  :grupos="this.grupos"
              />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click.prevent="cancelarEdicaoOpcao">
                  Cancelar
                </v-btn>
                <v-btn color="primary" type="submit"> Salvar</v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-if="dialogSala"
        v-model="dialogSala"
        width="85%"
        persistent
        scrollable
    >
      <v-card>
        <v-card-title class="primary justify-start white--text">
          {{ opcaoEditada.nomeOpcao }}
          <v-spacer/>
          <v-btn icon color="white" @click="dialogSala = false">
            <v-icon> mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="mt-5 d-flex justify-end">
            <v-btn
                class="mr-2"
                small
                :disabled="loading"
                color="primary"
                @click="carregarLocaisOpcao"
            >
              <v-icon left>mdi-plus-thick</v-icon>
              Vincular Salas
            </v-btn>
          </div>
        </v-card-text>
        <SalaLocalProvaOpcao
            :salas-local-prova="salasLocalProva"
            :entidade-oid="opcaoEditada.oid"
            :pode-criar="false"
            @excluirSalaLocalProva="excluirSalaOpcao"
            @atualizarSalas="atualizarSalas"
        />
      </v-card>
    </v-dialog>
    <v-dialog
        width="900"
        v-if="dialogImportarSalas"
        v-model="dialogImportarSalas"
        persistent
    >
      <v-card>
        <v-card-title
            class="primary justify-start white--text"
        >
          Vincular Salas
          <v-spacer/>
          <v-btn
              icon
              color="white"
              @click="dialogImportarSalas = false;
                      localProvaSelecionado = null;
                             "
          >
            <v-icon> mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-select
                v-model="localProvaSelecionado"
                dense
                :items="locaisProvaDoProcesso"
                item-text="nome"
                item-value="oid"
                label="Local de Prova"
                outlined
            />
          </v-container>
          <div class="d-flex justify-center">
            <SideBySideMultiSelect
                v-if="dialogImportarSalas"
                v-model="salasEscolhidasParaImportar"
                :items="salasLocalEscolhido"
            />
          </div>
          <v-card-actions class="justify-center">
            <v-btn
                color="error"
                @click="dialogImportarSalas = false; localProvaSelecionado = null;"
            >Cancelar
            </v-btn>
            <v-btn
                color="primary"
                @click="vincularSalasNaOpcao(); dialogImportarSalas = false;"
            >Confirmar
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import opcaoService from "@/services/opcao.service";
import grupoService from "@/services/grupo.service";
import processoService from "@/services/processo.service";
import tipoAlocacaoService from "@/services/tipoAlocacao.service";
import SideBySideMultiSelect from "@/components/base/SideBySideMultiSelect";
import EscolhaProcessoAutocomplete from "@/components/base/EscolhaProcessoAutocomplete";
import {mapActions, mapState} from "vuex";
import salaLocalProvaService from "@/services/salaLocalProva.service";
import localProvaService from "@/services/localProva.service";
import OpcaoForm from "@/components/base/forms/OpcaoForm";
import SalaLocalProvaOpcao from "@/components/pages/admin/processos/SalaLocalProva";
import XLSX from "xlsx";

export default {
  name: "Opcoes",

  components: {
    SalaLocalProvaOpcao,
    OpcaoForm,
    SideBySideMultiSelect,
    EscolhaProcessoAutocomplete,
  },

  data() {
    return {
      numTotalElementos: 0,
      elementos: [],
      loading: false,
      options: {},
      headers: [
        {text: "", value: "data-table-expand", transient: true},
        {text: "Nome", value: "nome"},
        {text: "Turno", value: "turno"},
        {text: "Polo", value: "polo"},
        {text: "Formação", value: "formacao"},
        {text: "Código", value: "codigo", align: "center"},
        {text: "Número de Vagas", value: "numeroVagas", align: "center"},
        {
          text: "Tipo de Alocação",
          value: "tipoAlocacaoCodigo",
          align: "center",
        },
        {text: "Ações", value: "actions", sortable: false, align: "center", transient: true},
      ],
      sortBy: ["nome"],
      sortDesc: [false],
      expanded: [],
      paramsFiltro: {},
      paramsFiltroSetado: false,
      dialog: false,
      dialogSala: false,
      dialogImportarSalas: false,
      opcaoEditada: {
        opcaoPesoNotaEnem: {},
      },
      tiposAlocacao: [],
      processoSelecionadoImportacao: {},
      processosAnteriores: [],
      opcoesProcessoAnterior: [],
      opcoesEscolhidasParaImportar: [],
      salasEscolhidasParaImportar: [],
      salasLocalEscolhido: [],
      grupos: [],
      locaisProvaDoProcesso: [],
      localProvaSelecionado: {},
      salasLocalProva: [],
      opcoes: []
    };
  },

  async created() {
    let loader = this.$loading.show();

    await tipoAlocacaoService
        .recuperarTiposAlocacao()
        .then((response) => {
          this.tiposAlocacao = response.data;
        })
        .catch(() => {
        });

    this.paramsFiltro = {
      processoOid: this.processoSelecionado.oid,
    };

    if (this.processoSelecionado.tipoProcesso.possuiGrupoOpcao) {
      this.opcaoEditada.grupo = {};
      await grupoService
          .recuperarGrupos()
          .then((response) => {
            this.grupos = response.data;
          })
          .catch(() => {
          });
    }

    this.paramsFiltroSetado = true;

    await this.getDataFromApi();

    loader.hide();
  },

  watch: {
    processoSelecionado: {
      handler() {
        window.scrollTo({top: 0, behavior: "smooth"});

        this.getDataFromApi();
      },
      deep: true,
    },

    options: {
      handler() {
        if (this.paramsFiltroSetado) {
          window.scrollTo({top: 0, behavior: "smooth"});

          this.getDataFromApi();
        }
      },
      deep: true,
    },

    localProvaSelecionado: {
      handler() {
        if (this.localProvaSelecionado !== null) {
          this.salasLocalEscolhido = [];
          salaLocalProvaService
              .recuperarSalasDisponiveisParaVincularPorLocalProva(this.localProvaSelecionado)
              .then((response) => {
                this.salasLocalEscolhido = response.data;
              });
        }
      },
    },

    processoSelecionadoImportacao: {
      async handler() {
        if (this.processoSelecionadoImportacao.oid) {
          const loader = this.$loading.show();
          await opcaoService
              .recuperarOpcoesImportacaoPorProcesso(this.processoSelecionadoImportacao.oid)
              .then((response) => {
                loader.hide();
                this.opcoesProcessoAnterior = response.data;
              })
              .catch(() => {
                loader.hide();
              });
        } else {
          this.opcoesProcessoAnterior = [];
        }
      },
    },
  },

  computed: {
    ...mapState("processoSelecionado", ["processoSelecionado"]),

    isCriandoOpcao() {
      return !this.opcaoEditada.oid;
    }
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    async carregarSalasOpcao(opcao) {
      let loading = this.$loading.show();

      await opcaoService.recuperarOpcaoPorOid(opcao.oid).then((response) => {
        this.opcaoEditada = response.data;
        this.salasLocalProva = this.opcaoEditada.salasLocalProva ?? [];
      });

      this.dialogSala = true;
      loading.hide();
    },

    async atualizarSalas(oid) {
      await opcaoService.recuperarOpcaoPorOid(oid).then((response) => {
        this.salasLocalProva = response.data.salasLocalProva ?? [];
      });
    },

    async recuperarLocaisDoProcesso() {
      await localProvaService
          .recuperarLocaisProvaPorProcesso(this.processoSelecionado.oid)
          .then((response) => {
            this.locaisProvaDoProcesso = response.data;
          });
    },

    async vincularSalasNaOpcao() {
      let loader = this.$loading.show();
      await opcaoService
          .vincularSalasOpcao(
              this.salasEscolhidasParaImportar,
              this.opcaoEditada.oid
          )
          .then(async () => {
            await this.recuperarOpcaoParaEdicao(this.opcaoEditada);
            this.salasLocalProva = this.opcaoEditada.salasLocalProva;
            loader.hide();
            this.localProvaSelecionado = null;
            this.exibirAviso({
              mensagem: "Sala(s) vinculada(s) com sucesso!",
              tipo: "success",
            });
          })
          .catch((error) => {
            loader.hide();
            let mensagem = "Erro ao vincular salas!";
            if (error.response.status === 409) {
              mensagem += " " + error.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error",
            });
          });
    },

    criarOpcao() {
      this.opcaoEditada = {opcaoPesoNotaEnem: {}};
      this.dialog = true;
    },

    async carregarLocaisOpcao() {
      this.salasLocalEscolhido = []
      await this.recuperarLocaisDoProcesso();
      this.salasEscolhidasParaImportar = []
      this.dialogImportarSalas = true;
    },

    defaultValue(header, value) {
      if (typeof value !== "number" && !value) {
        header.align = "center";
        return "-";
      }

      return value;
    },

    async getDataFromApi() {
      this.loading = true;

      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      let ordenacao = [];

      for (let i = 0; i < sortBy.length; i++) {
        ordenacao.push({direcao: +sortDesc[i], coluna: sortBy[i]});
      }

      await opcaoService
          .recuperarFiltro(this.paramsFiltro, page - 1, itemsPerPage, ordenacao)
          .then((response) => {
            this.elementos = response.data.elementos;
            this.opcoes = response.data;
            this.numTotalElementos = response.data.numTotalElementos;
          });
      this.loading = false;
    },

    cancelarEdicaoOpcao() {
      this.opcaoEditada = {opcaoPesoNotaEnem: {}};
      this.$refs.edicaoOpcaoForm.reset();
      this.dialog = false;
    },

    async salvarOpcaoEditada() {
      if (this.$refs.edicaoOpcaoForm.validate()) {
        let loader = this.$loading.show();

        if (this.isCriandoOpcao) {
          await opcaoService
              .criarOpcao(this.opcaoEditada, this.processoSelecionado.oid)
              .then(() => {
                this.getDataFromApi();

                this.exibirAviso({
                  mensagem: "Opção criada com sucesso!",
                  tipo: "success",
                });
              })
              .catch((error) => {
                let mensagem = "Erro ao criar opção!";
                if (error.response.status === 409) {
                  mensagem += " " + error.response.data.msg;
                }
                this.exibirAviso({
                  mensagem: mensagem,
                  tipo: "error",
                });
              });
        } else {
          await opcaoService
              .atualizarOpcao(this.opcaoEditada, this.processoSelecionado.oid)
              .then(() => {
                this.getDataFromApi();

                this.exibirAviso({
                  mensagem: "Opção atualizada com sucesso!",
                  tipo: "success",
                });
              })
              .catch(() => {
                this.exibirAviso({
                  mensagem: "Erro ao atualizar opção",
                  tipo: "error",
                });
              });
        }
        this.cancelarEdicaoOpcao();

        loader.hide();
      }
    },

    async editarOpcao(opcao) {
      await this.recuperarOpcaoParaEdicao(opcao);
      this.dialog = true;
    },

    async recuperarOpcaoParaEdicao(opcao) {
      this.opcaoEditada = {opcaoPesoNotaEnem: {}};
      await opcaoService.recuperarOpcaoPorOid(opcao.oid).then((response) => {
        this.opcaoEditada = {
          ...this.opcaoEditada,
          ...response.data,
        };
      });
    },

    async deletarOpcao(opcao) {
      let loader = this.$loading.show();
      await opcaoService
          .deletarOpcao(opcao.oid)
          .then(() => {
            this.getDataFromApi();
            loader.hide();
            this.exibirAviso({
              mensagem: "Opção excluída com sucesso!",
              tipo: "success",
            });
          })
          .catch((error) => {
            loader.hide();
            let mensagem = "Erro ao excluir opção";
            if (error.response.status === 409) {
              mensagem += ": " + error.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error",
            });
          });
    },

    async recuperarProcessosAnteriores() {
      this.opcoesProcessoAnterior = [];
      await processoService
          .recuperarProcessosConsulta()
          .then((response) => {
            this.processosAnteriores = response.data;
          });
    },

    async importarOpcoes() {
      let loader = this.$loading.show();
      await opcaoService
          .importarOpcoes(
              this.opcoesEscolhidasParaImportar,
              this.processoSelecionado.oid
          )
          .then(() => {
            loader.hide();
            this.exibirAviso({
              mensagem: "Importação realizada com sucesso!",
              tipo: "success",
            });
            this.getDataFromApi();
          })
          .catch(() => {
            loader.hide();
            this.exibirAviso({
              mensagem: "Erro ao realizar importação",
              tipo: "error",
            });
          });
    },

    async excluirSalaOpcao(salaLocalProva) {
      let loader = this.$loading.show();

      await opcaoService.deletarSalaOpcao(this.opcaoEditada.oid, salaLocalProva.oid).then(async () => {
        this.salasLocalProva.splice(this.salasLocalProva.indexOf(salaLocalProva), 1);
        this.exibirAviso({
          mensagem: "Sala excluída com sucesso",
          tipo: "success"
        });
      }).catch((error) => {
        let mensagem = "Erro ao desvincular sala";
        if (error.response.status === 409) {
          mensagem += ": " + error.response.data.msg;
        }
        this.exibirAviso({
          mensagem: mensagem,
          tipo: "error"
        });
      });

      loader.hide();
    },

    async exportarTabela() {
      let loader = this.$loading.show();

      const {sortBy, sortDesc} = this.options;

      let ordenacao = [];

      for (let i = 0; i < sortBy.length; i++) {
        ordenacao.push({direcao: +sortDesc[i], coluna: sortBy[i]});
      }

      let resultado;

      await opcaoService
          .recuperarFiltro(this.paramsFiltro, 0, this.numTotalElementos, ordenacao)
          .then((response) => {
            resultado = response.data.elementos;
          });

      if (resultado) {
        let header = this.headers.filter(h => h.transient !== true).map(h => h.value);
        let dataRows = [];
        resultado.map(row => {
          let dataRow = [];

          header.map(column => {
            dataRow.push(row[column]);
          });
          dataRows.push(dataRow);
        });

        let nomes = this.headers.filter(h => h.transient !== true).map(h => h.text);

        const ws = XLSX.utils.aoa_to_sheet([nomes, ...dataRows]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, this.titulo);
        XLSX.writeFile(wb, this.processoSelecionado.nome + ".xlsx");
      }

      loader.hide();
    },
  },
};
</script>

<style scoped></style>
